<template>
  <form
    class="forgot-password-form"
    novalidate
    @submit.prevent
    @keyup.enter.prevent
  >
    <h1 class="forgot-password-form__title">Forgot password?</h1>
    <div class="forgot-password-form__subtitle text">
      Enter the email address you used when you joined and we’ll send you
      instructions to reset your password.
    </div>
    <div class="forgot-password-form__items">
      <div class="forgot-password-form__item">
        <LabelField
          id="email"
          class="forgot-password-form__label"
          title="E-Mail"
        />
        <TextField
          id="email"
          v-model.trim="email"
          name="email"
          required
          class="forgot-password-form__input"
          type="email"
          :error="isEmailError"
          :error-txt="emailErrorText"
          tooltip-text="Enter your e-mail address"
          @focus="resetServerError('email')"
        />
      </div>
    </div>
    <div class="forgot-password-form__controls">
      <ButtonBase :loading="loading" size="max" @click="forgotPasswordHandler">
        Next
      </ButtonBase>
    </div>
  </form>
</template>

<script>
import forgotPasswordValidation from '@/mixins/validation/forms/forgotPassword.js';
import LabelField from '@/elements/LabelField';

export default {
  components: {
    LabelField,
  },
  mixins: [forgotPasswordValidation],
  data() {
    return {
      email: '',
      loading: false,
    };
  },
  created() {
    if (!!this.$route.params.email) this.email = this.$route.params.email;
  },
  methods: {
    async forgotPasswordHandler() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }
      this.loading = true;
      await this.$store.dispatch('resetPassword', {
        email: this.email,
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.forgot-password-form__subtitle
  margin-top: 12px
  margin-bottom: 64px
  @include ms
    margin-top: 8px
    margin-bottom: 40px

.forgot-password-form__items
  text-align: left

.forgot-password-form__caption
  display: inline-block
  margin-top: 20px
  color: $primary-color-blue
  @include adaptive-font-size(14, 14, 12)
  @include adaptive-line-height(21, 21, 18)
  font-weight: 600
  @include ms
    margin-top: 30px

.forgot-password-form__controls
  display: flex
  margin-top: 52px
  @include l
    margin-top: 40px
  @include ms
    margin-top: 32px
</style>
