import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getErrors']),

    isInvalidForm() {
      return this.$v.$invalid;
    },
  },
  destroyed() {
    if (!!Object.keys(this.getErrors).length)
      this.$store.commit('RESET_ERRORS');
  },
  methods: {
    showValidationErrors() {
      this.$v.$touch();
    },
    hideValidationErrors() {
      this.$v.$reset();
    },
    resetServerError(fieldName) {
      if (!!this.getErrors[fieldName])
        this.$store.commit('RESET_FIELD_ERROR', fieldName);
    },
  },
};
